import React from 'react'
import { Link } from 'gatsby'
import { Disclosure } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'


// Images
import LogoImage from '../images/Invense_LOGO.svg';

//Helpers
import { classNames } from '../helpers/common.js';

const navigation = [
  { name: 'Home', href: '/', current: false },
  { name: 'Products', href: '/products', current: false },
  { name: 'Use Cases', href: '/#where-invense', current: false },
  { name: 'Contact Us', href: '/#contact-us', current: false },
]

export default function Header() {
    return (
      <Disclosure as="nav" className="z-10 bg-blue-200 fixed w-full">
        {({ open }) => (
          <>
            <div className="max-w-6xl mx-auto px-2 sm:px-6 lg:px-8">
              <div className="relative flex items-center justify-between">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-between">
                  <Link to="/" className="flex-shrink-0 flex items-center">
                    <img
                      className="py-4 block w-auto h-16"
                      src={LogoImage}
                      alt="Workflow"
                    />
                  </Link>
                  <div className="hidden sm:flex items-center">
                    <div className="flex gap-x-4">
                      {navigation.map((item) => (
                        <Link
                          key={item.name}
                          to={item.href}
                          className={classNames(
                            item.current ? 'bg-primary text-white' : 'text-primary hover:bg-primary hover:text-white',
                            'py-6 px-4 text-sm font-medium uppercase'
                          )}
                          aria-current={item.current ? 'page' : undefined}
                        >
                          {item.name}
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
  
            <Disclosure.Panel className="sm:hidden">
              <div className="flex flex-col">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className={classNames(
                      item.current ? 'bg-primary text-white' : 'text-primary hover:bg-primary hover:text-white',
                      'block p-4 text-base font-medium uppercase'
                    )}
                    aria-current={item.current ? 'page' : undefined}
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    )
  }