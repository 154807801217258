import React from 'react'
import { Link } from "gatsby"

// Images
import InvenseGeryLogo from '../images/footer-logo.png';
import FooterBackground from '../images/footer-background.webp';

// Icons
import linkedIn from '../images/linkedin.png'
import twitter from '../images/twitter.png'
import addressIcon from '../images/address-icon.png'
import phoneIcon from '../images/phone-icon.png'
import mailIcon from '../images/email-icon.png'
import tickIcon from '../images/tick-icon.png'

export default function Footer() {
    return (
      <footer className="border-t-8 border-primary" style={{ backgroundImage: `url(${FooterBackground})`, backgroundSize: 'cover' }}>
        <div className="max-w-6xl mx-auto px-2 sm:px-6 lg:px-8 py-4 md:flex divide-y-2 md:divide-y-0 md:divide-x-2 divide-primary">
          <div className="flex-1 p-4">
              <img src={InvenseGeryLogo} style={{ width: 250, margin: 'auto' }} alt="scripbox logo in grey" />
              <div className="flex justify-center">
                <a href="https://in.linkedin.com/company/invense-technologies" target="_blank" rel="noreferrer">
                  <img src={linkedIn} alt="linkedin" className="w-10 h-10" />
                </a>
                <a target="_blank" rel="noreferrer">
                  <img src={twitter} alt="twitter" className="w-10 h-10" />
                </a>
              </div>
          </div>
          <div className="flex-1 md:flex md:justify-center p-4">
              <div className="">
                <div className="text-lg text-white">Additional Links</div>
                <ul className="mt-5 text-md uppercase text-white">
                  <li>
                    <Link to="/" className="flex gap-3"> <img src={tickIcon} alt="home-page" className="w-5 h-5" /> Home</Link>
                  </li>
                  <li className="mt-5">
                    <Link to="/products" className="flex gap-3"> <img src={tickIcon} alt="products-page" className="w-5 h-5" /> Products</Link>
                  </li>
                  <li className="mt-5">
                    <Link to="/#contact-us" className="flex gap-3"> <img src={tickIcon} alt="contact-us" className="w-5 h-5" /> Request A Demo</Link>
                  </li>
                </ul>    
              </div>
          </div>
          <div className="flex-1 md:flex md:justify-center p-4">
            <div className="">
              <div className="text-lg text-white">Contact Us</div>
              <div className="mt-5 text-sm text-white">
                <a
                  href="https://goo.gl/maps/BRoH8U767QFBR8wQ9"
                  target="_blank"
                  rel="noreferrer"
                  className="flex items-center gap-3"
                >
                  <img src={addressIcon} className="w-5 h-5" alt="address" />
                  <div className="flex-1">
                    No 12, F3, NHCS Layout, 13th Main Road <br/>
                    Vijayanagar, Bangalore, India - 560040
                  </div>
                </a>
                <a
                  href="tel:9974930331"
                  target="_blank"
                  rel="noreferrer"
                  className="mt-5 flex items-center gap-3"
                >
                  <img src={phoneIcon} className="w-5 h-5" alt="phone" />
                  <div className="flex-1">
                    +91-9974930331
                  </div>
                </a>
                <a
                  href="mailto:prashantv@invene.in"
                  target="_blank"
                  rel="noreferrer"
                  className="mt-5 flex items-center gap-3"
                >
                  <img src={mailIcon} className="w-5 h-5" alt="email" />
                  <div className="flex-1">
                    prashantv@invene.in
                  </div>
                </a>
              </div>    
            </div>
          </div>
        </div>
        <div className="bg-black">
          <div className="max-w-6xl mx-auto px-2 sm:px-6 lg:px-8 py-4 text-center text-white">
            Copyright {(new Date()).getFullYear()}. <a href="/" className="text-secondary">INVENSE Technologies Pvt. Ltd.</a> All Rights Reserved
          </div>
        </div>
      </footer>
    )
  }